import { AnyAction } from 'redux';

import { WeeklyStandupFilterValues } from '../../../components/Filter/WeeklyStandupFilter';
import { WorkloadStatusFilterValues } from '../../../components/Filter/WorkloadStatusFilter';
import EmptyEmployee from '../../../models/shared/EmptyEmployee';
import { EmptyTeamData } from '../../../models/shared/EmptyTeamData';
import { sortEmployeeList } from '../../../utils/employeeUtils';
import * as actionTypes from '../../actions/ActionTypes';
import { Employee, EmployeeStore, WeeklyStandup } from './employee.type';

export const employeeInitialData: EmployeeStore = {
  displayedEmployees: [],
  employeeData: [],
  openEmployeeCards: [],
  shownSpecificTeam: 'alle',
  teamData: EmptyTeamData,
  statusTag: [],
  profileImage: '',
  weeklyStandupFilter: WeeklyStandupFilterValues.all,
  workloadStatusFilter: WorkloadStatusFilterValues.all,
  currentEmployee: EmptyEmployee,
};

function EmployeeReducer(state = employeeInitialData, action: AnyAction): EmployeeStore {
  switch (action.type) {
    case actionTypes.EMPLOYEES_DATA_AVAILABLE: {
      // Action is called team-wise!
      const employeeData = [...state.employeeData];
      const displayedData = [...state.displayedEmployees];
      const listOfAllIds = employeeData.map((employee) => employee.id);
      let currentEmployee = state.currentEmployee;

      // Add new employees to general employeeData
      action.payload.forEach((employee: Employee) => {
        if (currentEmployee.id === employee.id) {
          currentEmployee = employee;
        }
        // If the new employee is not already in allEmployeeData, then add him
        if (!listOfAllIds.includes(employee.id)) {
          employeeData.push(employee);
          listOfAllIds.push(employee.id);
          displayedData.push(employee);
        } else {
          const empIndex = employeeData.findIndex((oldEmp) => oldEmp.id === employee.id);
          employeeData[empIndex] = employee;
          const displayedIndex = displayedData.findIndex((oldEmp) => oldEmp.id === employee.id);
          displayedData[displayedIndex] = employee;
        }
      });

      return {
        ...state,
        displayedEmployees: sortEmployeeList(displayedData),
        employeeData: sortEmployeeList(employeeData),
        currentEmployee: currentEmployee,
      };
    }

    case actionTypes.TEAM_DATA_AVAILABLE: {
      return {
        ...state,
        teamData: action.payload,
      };
    }

    case actionTypes.TEAM_FILTER_DATA_AVAILABLE: {
      return {
        ...state,
        displayedEmployees: state.employeeData,
        shownSpecificTeam: action.payload,
      };
    }

    case actionTypes.SET_EMPLOYEE: {
      const isDisplayedInList = !!state.displayedEmployees.find((employee) => employee.id === action.payload.id);
      return {
        ...state,
        currentEmployee: action.payload,
        employeeData: [...state.employeeData.filter((employee) => employee.id !== action.payload.id), action.payload],
        displayedEmployees: isDisplayedInList
          ? sortEmployeeList([
              ...state.displayedEmployees.filter((employee) => employee.id !== action.payload.id),
              action.payload,
            ])
          : [...state.displayedEmployees],
      };
    }

    case actionTypes.SET_EMPLOYEE_FILTERS: {
      let currentDisplayedEmployees = [...state.employeeData];
      const currentShownSpecificTeam = action.payload.teamFilter ?? state.shownSpecificTeam;
      const currentWeeklyStandupFilter = action.payload.weeklyStandupFilter ?? state.weeklyStandupFilter;
      const currentWorkloadStatusFilter = action.payload.workloadStatusFilter ?? state.workloadStatusFilter;

      // Filter all employees which are in the "currentShownSpecificTeam"
      if (currentShownSpecificTeam !== 'alle') {
        currentDisplayedEmployees = currentDisplayedEmployees.filter((employee: Employee) => {
          return employee.teams.includes(currentShownSpecificTeam);
        });
      }

      if (currentWeeklyStandupFilter !== WeeklyStandupFilterValues.all) {
        currentDisplayedEmployees = currentDisplayedEmployees.filter(
          (employee) => employee.status.weeklyStandupStatus === WeeklyStandup.I_AM_HERE,
        );
      }

      switch (currentWorkloadStatusFilter) {
        case WorkloadStatusFilterValues.iHaveTime: {
          currentDisplayedEmployees = currentDisplayedEmployees.filter(
            (employee) => employee.status.workloadStatus.iHaveTime,
          );
          break;
        }
        case WorkloadStatusFilterValues.iNeedHelp: {
          currentDisplayedEmployees = currentDisplayedEmployees.filter(
            (employee) => employee.status.workloadStatus.iNeedHelp,
          );
          break;
        }
      }

      return {
        ...state,
        workloadStatusFilter: currentWorkloadStatusFilter,
        weeklyStandupFilter: currentWeeklyStandupFilter,
        displayedEmployees: sortEmployeeList(currentDisplayedEmployees),
        shownSpecificTeam: currentShownSpecificTeam,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
export default EmployeeReducer;
