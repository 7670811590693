import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import * as appService from '../../services/appServices/appInitService';
import { ApplicationStore } from '../reducers';
import { Employee } from '../reducers/employee/employee.type';
import * as appSettingActions from './AppSettingAction';
import * as employeeActions from './EmployeeActions';
import * as projectActions from './ProjectActions';
import { Project } from './ProjectActions';

const all = 'alle';

const getPrimaryTeams = async (): Promise<string[]> => {
  return await appService
    .getPrimaryTeamData()
    .then((teamData: string[]) => {
      return teamData ? teamData : [];
    })
    .catch(() => {
      console.error('No TeamData in AppInitAction.ts');
      return [];
    });
};

const getSubTeams = async (): Promise<string[]> => {
  return await appService
    .getSubTeamData()
    .then((teamData: string[]) => {
      return teamData ? teamData : [];
    })
    .catch(() => {
      console.error('No TeamData in AppInitAction.ts');
      return [];
    });
};

const getAllEmployeesFromPrimaryTeams = async (
  dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>,
  allPrimaryTeams: string[],
): Promise<Employee[]> => {
  const allEmployees: Employee[] = [];
  const promises: Promise<any>[] = [];

  for (const primaryTeam of allPrimaryTeams) {
    promises.push(
      appService
        .getEmployeeData(primaryTeam)
        .then((data: Employee[]) => {
          dispatch(employeeActions.employeeDataAvailable(data));
          return data;
        })
        .catch((er: Error) => {
          console.error('No EmployeeData in AppInitAction.ts', er);
        }),
    );
  }
  Promise.all(promises).then((promise) => {
    for (const employee of promise) {
      allEmployees.push(employee);
    }
    dispatch(appSettingActions.AppStartedAction());
  });
  return allEmployees;
};

const getAllProjectData = async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>): Promise<void> => {
  await appService
    .getProjectData()
    .then((data: Project[]) => {
      dispatch(projectActions.projectDataAvailable(data));
    })
    .catch(() => {
      console.error('No ProjectData in AppInitAction.ts');
    });
};

export const initApp = () => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>) => {
    const allPrimaryTeams = await getPrimaryTeams();
    const allSubTeams = await getSubTeams();
    dispatch(employeeActions.teamFilterAvailable(all));
    dispatch(employeeActions.teamDataAvailable([...allPrimaryTeams, all], allSubTeams));
    // dont wait for all projects because employee projects are loaded when they are selected
    // and other projects can start loading in background
    getAllProjectData(dispatch);
    await getAllEmployeesFromPrimaryTeams(dispatch, allPrimaryTeams);
  };
};
